<template>
  <NavQuestion
    v-bind:heading="'Document Checking'"
    v-bind:subHeading="subHeading"
    v-bind:background="'bg-desk'"
    v-bind:number="questionNumber"
    v-bind:introClasses="'width600 pb30'"
    v-bind:questionOptions="true"
  >
    <template v-slot:contents>

        <div class="input-container">
          <input
              :value="email"
              type="text"
              id="email"
              class="input-standard-white width400"
              required
              disabled
              placeholder="Email Address"
              @keydown.enter.prevent="forward"
          />
          <label >Contact Email</label>
        </div>

      <div class="input-container">
        <input
          v-model="data.phone"
          type="text"
          id="phone"
          class="input-standard-grey width400 validation"
          required
          placeholder="Contact Number"
          @keydown.enter.prevent="forward"
        />
        <label for="phone">Contact Number</label>
      </div>


      <div class="input-container">
        <textarea
          v-model="dataWill.user_question"
          type="text"
          id="question"
          cols="5"
          rows="5"
          class="input-text input-standard-grey fadeIn-2 width400"
          required
          placeholder="If you would like to provide additional information for our checking team or have a question about your Will, include it here."
          @keydown.enter.prevent="forward"
        ></textarea>
      </div>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="validateForm"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        @forwardClick="saveContactDetails()"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import NavQuestion from '@/common/ui/NavQuestion'
import { http } from '@/services'

export default {
  name: 'CheckingPage',
  components: {
    NavQuestionButtons,
    NavQuestion
  },
  mounted() {
    this.data.phone = this.$store.getters.personalDetails.phone
    this.data.email = this.user.email

    if (!this.$store.getters.willPreview) {
      this.$store.dispatch('willPreviewFetch')
    }

    this.$store.commit('checkoutProgress', {
      createWill: true,
      package: true,
      printing: true
    })
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
    validateForm() {
      if (this.data.phone) return true
      return false
    },
    phone() {
      return this.$store.getters.personalDetails.phone
    },
    email() {
      return this.user.email
    },
    userQuestion() {
      if (
        this.$store.getters.willPreview &&
        this.$store.getters.willPreview.will_ob
      )
        return this.$store.getters.willPreview.will_ob.user_question
      return ''
    },
    questionNumber() {
      if (this.$store.getters.checkoutBasket.active_subscription) return '2'
      return '3'
    },
    backTo() {
      if (this.$store.getters.checkoutBasket.active_subscription)
        return '/create_will'
      else if (this.$store.getters.checkoutBasket.package) return '/storage'
      return '/printing'
    },
    subHeading() {
      if (this.$store.getters.willUpdate)
        return 'The next step is for our specialists to check your amendments, this can take up-to 48 hours. Please add your contact details below so if any issues are identified we can contact you to discuss and rectify them.'
      return 'The next step is for our specialists to check your Will, this can take up-to 48 hours. Please add your contact details below so if any issues are identified we can contact you to discuss and rectify them.'
    }
  },
  watch: {
    phone(value) {
      this.data.phone = value
    },
    userQuestion: {
      immediate: true,
      handler(value) {
        if (value) this.dataWill.user_question = value
      }
    }
  },
  data() {
    return {
      data: {
        phone: '',
      },
      dataWill: {
        user_question: ''
      },
      forwardTo: '/pay'
    }
  },
  beforeRouteLeave(to, from, next) {
    this.saveContactDetails()
    this.saveUserQuestion()
    next()
  },
  methods: {
    saveContactDetails() {
      http
        .patch('/wills/api/personal_details', this.data)
        .then((response) => {
          this.$store.commit('personalDetails', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    saveUserQuestion() {
      http
        .patch('/wills/api/will_detail', this.dataWill)
        .then((response) => {
          this.$store.commit('willPreview', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    forward(event) {
      event.target.blur()
    }
  }
}
</script>
